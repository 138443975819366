uniform sampler2D tBackground;

varying vec2 vUv;

void main()
{

vec4 backgroundColor = texture(tBackground, vUv);
backgroundColor.rgb *= 0.99; // Verdunkelt die Farbe (Dark Mode)
gl_FragColor = backgroundColor;

}