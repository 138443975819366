import * as THREE from 'three';
import FloorMaterial from '../Materials/Floor.js';

export default class Floor {
    constructor(_options) {
        // Optionen
        this.debug = _options.debug;

        // Container
        this.container = new THREE.Object3D();
        this.container.matrixAutoUpdate = false;

        // Geometrie
        this.geometry = new THREE.PlaneGeometry(2, 2, 10, 10);

        // Farben für die verschiedenen Tageszeiten
        this.colors = {
            night1: {
                topLeft: '#1a1a1a', // Fast Schwarz
                topRight: '#121212',
                bottomRight: '#0f0f0f',
                bottomLeft: '#0c0c0c',
            },
            night2: {
                topLeft: '#2a2a2a', // Dunkelgrau
                topRight: '#252525',
                bottomRight: '#202020',
                bottomLeft: '#1c1c1c',
            },
            dawn: {
                topLeft: '#1f3a4d', // Kühle Dämmerung
                topRight: '#3f5c71', // Sanftes Blau
                bottomRight: '#536d7a', // Helles Blau mit Grau
                bottomLeft: '#6d7e8f', // Zartes Blau
            },
            morning: {
                topLeft: '#ffd700', // Sonnengelb
                topRight: '#ffcc00', // Helles Gold
                bottomRight: '#f7b500', // Weiches Orange
                bottomLeft: '#ff9900', // Zartes Orange
            },
            noon: {
                topLeft: '#f1c232', // Helles Gelb
                topRight: '#f7bc00', // Intensives Gelb-Orange
                bottomRight: '#ffdc57', // Helles Sonnenorange
                bottomLeft: '#fbbd34', // Warmer Sonnenschein
            },
            afternoon: {
                topLeft: '#e74c3c', // Rotes Orange
                topRight: '#f39c12', // Mildes Orange
                bottomRight: '#d87f3c', // Helles Korallenrot
                bottomLeft: '#e67e22', // Warmer
            },
            evening: {
                topLeft: '#af4f4b', // Rot
                topRight: '#ca5c5b',
                bottomRight: '#b26d6a',
                bottomLeft: '#9b7f7d',
            },
            night3: {
                topLeft: '#2c2c2c', // Sehr dunkles Grau
                topRight: '#1f1f1f', // Noch dunkler
                bottomRight: '#1a1a1a', // Fast Schwarz
                bottomLeft: '#141414', // Fast Schwarz
            }
        };

        // Zu Beginn wird der "Mittag"-Farbsatz gesetzt
        this.colors.current = { ...this.colors.noon };

        // Material
        this.material = new FloorMaterial();

        this.updateMaterial = () => {
            const topLeft = new THREE.Color(this.colors.current.topLeft);
            const topRight = new THREE.Color(this.colors.current.topRight);
            const bottomRight = new THREE.Color(this.colors.current.bottomRight);
            const bottomLeft = new THREE.Color(this.colors.current.bottomLeft);

            topLeft.convertLinearToSRGB();
            topRight.convertLinearToSRGB();
            bottomRight.convertLinearToSRGB();
            bottomLeft.convertLinearToSRGB();

            const data = new Uint8Array([
                Math.round(bottomLeft.r * 255), Math.round(bottomLeft.g * 255), Math.round(bottomLeft.b * 255), 255,
                Math.round(bottomRight.r * 255), Math.round(bottomRight.g * 255), Math.round(bottomRight.b * 255), 255,
                Math.round(topLeft.r * 255), Math.round(topLeft.g * 255), Math.round(topLeft.b * 255), 255,
                Math.round(topRight.r * 255), Math.round(topRight.g * 255), Math.round(topRight.b * 255), 255,
            ]);

            this.backgroundTexture = new THREE.DataTexture(data, 2, 2);
            this.backgroundTexture.magFilter = THREE.LinearFilter;
            this.backgroundTexture.needsUpdate = true;

            this.material.uniforms.tBackground.value = this.backgroundTexture;
        };

        this.updateMaterial();

        // Mesh
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.frustumCulled = false;
        this.mesh.matrixAutoUpdate = false;
        this.mesh.updateMatrix();
        this.container.add(this.mesh);

        // Farben beim Laden festlegen (Sofortiger Aufruf)
        this.updateColors();

        // Debug
        if (this.debug) {
            const folder = this.debug.addFolder('floor');
            folder.addColor(this.colors.morning, 'topLeft').onChange(this.updateMaterial);
            folder.addColor(this.colors.morning, 'topRight').onChange(this.updateMaterial);
            folder.addColor(this.colors.morning, 'bottomRight').onChange(this.updateMaterial);
            folder.addColor(this.colors.morning, 'bottomLeft').onChange(this.updateMaterial);
        }
    }

    updateColors() {
        const currentHour = new Date().getHours();

        // Bestimmen der Farben je nach Tageszeit
        if (currentHour >= 0 && currentHour < 3) {
            this.colors.current = { ...this.colors.night1 }; // 00:00 - 03:00
        } else if (currentHour >= 3 && currentHour < 6) {
            this.colors.current = { ...this.colors.night2 }; // 03:00 - 06:00
        } else if (currentHour >= 6 && currentHour < 9) {
            this.colors.current = { ...this.colors.dawn }; // 06:00 - 09:00
        } else if (currentHour >= 9 && currentHour < 12) {
            this.colors.current = { ...this.colors.morning }; // 09:00 - 12:00
        } else if (currentHour >= 12 && currentHour < 15) {
            this.colors.current = { ...this.colors.noon }; // 12:00 - 15:00
        } else if (currentHour >= 15 && currentHour < 18) {
            this.colors.current = { ...this.colors.afternoon }; // 15:00 - 18:00
        } else if (currentHour >= 18 && currentHour < 21) {
            this.colors.current = { ...this.colors.evening }; // 18:00 - 21:00
        } else {
            this.colors.current = { ...this.colors.night3 }; // 21:00 - 00:00
        }

        // Material mit den neuen Farben aktualisieren
        this.updateMaterial();
    }
}
